<template>
  <span class="status-text">
    <span v-if="type" class="status-dot" :class="[`status-dot--${type}`]"></span>
    <slot></slot>
  </span>
</template>
<script>
  // 定义常量
  export const StatusDotEnum = {
    PENDING: 'pending',
    DOING: 'doing',
    SUCCESS: 'success',
    FAILURE: 'failure'
  };

  export default {
    name: 'StatusText',
    props: {
      type: {
        type: String,
        default: ''
      }
    }
  };
</script>
<style lang="less" scoped>
  .status-text {
    display: inline-block;

    .status-dot {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50px;
      line-height: 20px;
      vertical-align: middle;
      margin-right: 4px;

      &--pending {
        background: #8c8c8c;
      }
      &--doing {
        background: #0a7ff5;
      }
      &--success {
        background: #0fba5a;
      }
      &-failure {
        background: #fb2626;
      }
    }
  }
</style>
