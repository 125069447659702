<template>
  <div class="page-content page-content--list-box">
    <!-- 操作栏插槽 -->
    <div class="search-bar">
      <slot name="search"></slot>
    </div>
    <!-- 内容插槽 -->
    <slot></slot>
    <div class="page-content__footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PageListBox'
  };
</script>
<style lang="less">
  .page-content--list-box {
    min-height: 100%;
    width: 100%;

    .search-bar {
      margin-bottom: 16px;

      .search-input {
        float: right;
        width: 220px;

        &.vh-input {
          ::v-deep .vh-input__inner {
            height: 36px;
            line-height: 36px;
          }
          ::v-deep .vh-input__icon {
            line-height: 36px;
          }
        }
      }
    }

    .page-content__body {
      flex: 1;
      flex-shrink: 0;
      height: 0;
      display: flex;
      flex-direction: column;
    }
    .vh-table {
      // width: 100%;
      // overflow: auto;
      // max-width: 100%;
      // overflow-x: auto;

      // 解决vh-table宽度无限延长问题
      // .vh-table__header-wrapper table,
      // .vh-table__body-wrapper table {
      //   width: 100% !important;
      //   overflow: auto;
      // max-width: 100%;
      // }

      .vh-table__empty-text {
        line-height: 1;
      }
    }

    .vh-table .vh-table__row .cell {
      .image-preview {
        height: 72px;
        position: relative;

        .cover-mask {
          position: absolute;
          background: red;
          height: 20px;
          font-size: 12px;
          color: #fff;
          left: 1px;
          bottom: 1px;
          padding: 0 8px;
          background: rgba(0, 0, 0, 0.65);
          border-bottom-left-radius: 4px;
          border-top-right-radius: 12px;
          box-sizing: border-box;

          .vh-radio {
            color: #fff;
            box-sizing: border-box;

            .vh-radio__inner {
              width: 12px;
              height: 12px;

              &::after {
                width: 6px;
                height: 6px;
              }
            }
            .vh-radio__label {
              font-size: 12px;
              padding-left: 4px;
            }
          }
        }
      }

      .vh-image {
        border-radius: 4px;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        border: 1px solid #f0f0f0;
        object-fit: contain;
      }
    }
  }
</style>
